import './CheckDocument.scss'
import {Button, CircularProgress, Grid, TextField, Typography} from "@mui/material";
import React, {ChangeEvent, useState} from "react";
import {PDFContext, PDFDict, PDFDocument, PDFName} from 'pdf-lib'

export default function CheckDocumentForm(props: {callback: any}) {

    const [signatureTextFIeld, setSignatureTextFIeld] = useState("")
    const [file, setFile] = useState<any | null>(null)
    const [fileName, setFileName] = useState("")
    const [fileLoading, setFileLoading] = useState(false);
    function checkTextField() {
        if(signatureTextFIeld) {
            props.callback(signatureTextFIeld)
        }
    }

    function onChangeFile(e: ChangeEvent<HTMLInputElement>) {
        if (!e.target.files) {
            return;
        }
        const data = e.target.files[0];
        setFile(data)
        const { name } = data;
        setFileName(name)
    }

    function getInfoDict(context: PDFContext): PDFDict {
        const existingInfo = context.lookup(context.trailerInfo.Info);
        if (existingInfo instanceof PDFDict) return existingInfo;
        return context.obj({})
    }

    function readFile() {
        setFileLoading(true)
        var reader = new FileReader();
        reader.onload = function (evt) {
            PDFDocument.load(evt.target!.result as ArrayBuffer, {
                updateMetadata: false
            }).then(pdf => {
                let meta = getInfoDict(pdf.context)
                let number = meta.lookup(PDFName.of('ID'));
                setFileLoading(false)
                if(!number) {
                    setFile(null);
                    setFileName("Plik nie pochodzi z naszego systemu")
                } else {
                    props.callback(number.toString().replace("(", "").replace(")", ""))
                }
            })

        };

        reader.readAsArrayBuffer(file);
    }

    return (
        <>
            <Grid container spacing={3}>
                <Grid xs={12}>
                    <Typography align="left" variant="h1">Sprawdź sygnaturę pisma</Typography>
                </Grid>
                <Grid xs={12}>
                    <Grid container spacing={2}>
                        <Grid className="blockWrapper" xs={12} md={4}><div className="block">
                            <div className="blockInsideWrapper">
                                <p>Wgraj plik pisma podpisanego przez system epismo.app</p>
                                <div>
                                    <Button variant="outlined" component="label">
                                        {!fileName && <>Wybierz plik z dysku</>}
                                        {fileName && <>{fileName}</>}
                                        <input
                                            type="file"
                                            accept="application/pdf"
                                            hidden
                                            onChange={onChangeFile}
                                        /></Button>
                                </div>
                                <div>
                                    <Button variant="contained" onClick={readFile} disabled={!file}>Sprawdź pismo {fileLoading && <CircularProgress size={20} sx={{ml: 1}} />}</Button>
                                </div>
                            </div>
                        </div></Grid>
                        <Grid className="blockWrapper" xs={12} md={4}><div className="block">
                            <p>Wpisz poniżej numer sygnatury widoczny na dokumencie</p>
                            <TextField label="Number sygnatury" variant="outlined" size="small" onChange={e => setSignatureTextFIeld(e.target.value)}></TextField>
                            <Button variant="contained" onClick={checkTextField}>Sprawdź pismo</Button>
                        </div></Grid>
                        <Grid className="blockWrapper" xs={12} md={4}><div className="block">
                            <p>Zeskanuj aparatem w telefonie kod QR widoczny na dokumencie</p>
                        </div></Grid>
                    </Grid>
                </Grid>

            </Grid>
        </>
    )
}