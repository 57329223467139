import {useKeycloak} from "@react-keycloak/web";
import axios from "axios";

export class EPismoClient {
    getToken(keycloak: any) {
        if(!keycloak.initialized || !keycloak.keycloak.authenticated || keycloak.keycloak.token == null) {
            throw new Error("No token")
        }
        return keycloak.keycloak.token;
    }

    get(endpoint:String, keycloak: any) {
        return axios.get(`${process.env.REACT_APP_EPISMO_URL}` + endpoint , {
            headers: {
                'Authorization': 'Bearer ' + this.getToken(keycloak),
                'Access-Control-Allow-Origin': '*'
            }
        })
    }

    getPublic(endpoint:String) {
        return axios.get(`${process.env.REACT_APP_EPISMO_URL}` + endpoint , {
            headers: {
                'Access-Control-Allow-Origin': '*'
            }
        })
    }

    getFile(endpoint:String, keycloak: any) {
        return axios.get(`${process.env.REACT_APP_EPISMO_URL}` + endpoint , {
            responseType: 'blob',
            headers: {
                'Authorization': 'Bearer ' + this.getToken(keycloak),
                'Access-Control-Allow-Origin': '*'
            }
        })
    }
    post(endpoint:String, keycloak: any, data: any) {
        return axios.post(`${process.env.REACT_APP_EPISMO_URL}` + endpoint , data, {
            headers: {
                'Authorization': 'Bearer ' + this.getToken(keycloak),
                'Access-Control-Allow-Origin': '*'
            }
        })
    }

}

export const ePismoClientInstance = new EPismoClient();