import './BuySubscriptionView.scss'
import {Grid, Box, Typography, Button} from "@mui/material";

export default function BuySubscriptionView() {
    return (
        <>
            <p>Z przyjemnością przedstawiamy nową ofertę subskrypcyjną po rocznym okresie promocyjnym zgodnym z regulaminem serwisu epismo.app:</p>
            <Grid container spacing={1} mt={3}>
                <Grid xs={12} lg={3} md={6} className="offerBoxWrapper">
                    <Box className="offerBox">
                        <Typography className="offerName">Parafia mała</Typography>
                        <Typography className="offerSubName">(do 1 000 osób)</Typography>
                        <Typography className="offerPrice">300 zł / rok</Typography>
                        <Typography className="offerSubName">Oszczędności związane z brakiem zakupu znaczka pocztowego:</Typography>
                        <ul>
                            <li>w 1 roku - <b>780 zł</b></li>
                            <li>w 2 roku - <b>870 zł</b></li>
                            <li>w 3 roku - <b>960 zł</b></li>
                            <li>w 4 roku - <b>1050 zł</b></li>
                            <li>w 5 roku - <b>1140 zł</b></li>
                        </ul>
                        <Button variant="contained" disabled>Wkrótce dostępne</Button>
                    </Box>
                </Grid>
                <Grid xs={12} lg={3} md={6}  className="offerBoxWrapper" >
                    <Box className="offerBox">
                        <Typography className="offerName">Parafia Średnia </Typography>
                        <Typography className="offerSubName">(1 000 - 4 999 osób)</Typography>
                        <Typography className="offerPrice">400 zł / rok</Typography>
                        <Typography className="offerSubName">Oszczędności związane z brakiem zakupu znaczka pocztowego:</Typography>
                        <ul>
                            <li>w 1 roku - <b>1090 zł</b></li>
                            <li>w 2 roku - <b>1210 zł</b></li>
                            <li>w 3 roku - <b>1330 zł</b></li>
                            <li>w 4 roku - <b>1450 zł</b></li>
                            <li>w 5 roku - <b>1570 zł</b></li>
                        </ul>
                        <Button variant="contained" disabled>Wkrótce dostępne</Button>
                    </Box>
                </Grid>
                <Grid xs={12} lg={3} md={6} className="offerBoxWrapper" >
                    <Box className="offerBox">
                        <Typography className="offerName">Parafia Duża</Typography>
                        <Typography className="offerSubName">(od 5000 osób)</Typography>
                        <Typography className="offerPrice">500 zł / rok</Typography>
                        <Typography className="offerSubName">Oszczędności związane z brakiem zakupu znaczka pocztowego:</Typography>
                        <ul>
                            <li>w 1 roku - <b>1400 zł</b></li>
                            <li>w 2 roku - <b>1550 zł</b></li>
                            <li>w 3 roku - <b>1700 zł</b></li>
                            <li>w 4 roku - <b>1850 zł</b></li>
                            <li>w 5 roku - <b>2000 zł</b></li>
                        </ul>
                        <Button variant="contained" disabled>Wkrótce dostępne</Button>
                    </Box>
                </Grid>
                <Grid xs={12}lg={3} md={6} className="offerBoxWrapper" >
                    <Box className="offerBox">
                        <Typography className="offerName">Instytucja kościelna</Typography>
                        <Typography className="offerSubName">np. Archidiecezja Warmińska</Typography>
                        <Typography className="offerPrice">500 zł / rok</Typography>
                        <Typography className="offerSubName">Oszczędności związane z brakiem zakupu znaczka pocztowego:</Typography>
                        <ul>
                            <li>w 1 roku - <b>17000 zł</b></li>
                            <li>w 2 roku - <b>18500 zł</b></li>
                            <li>w 3 roku - <b>20000 zł</b></li>
                            <li>w 4 roku - <b>21500 zł</b></li>
                            <li>w 5 roku - <b>23000 zł</b></li>
                        </ul>
                        <Button variant="contained" disabled>Wkrótce dostępne</Button>
                    </Box>
                </Grid>
            </Grid>
            <p>Nasza oferta łączy doskonałą jakość z przystępnymi cenami. Istnieje możliwość dostosowania obiegu "episma" do indywidualnych potrzeb instytucji. Zapraszamy do skorzystania!</p>
        </>
    )
}