import {Grid, Typography} from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AppHeaderButton from "./AppHeaderButton";
import {useKeycloak} from "@react-keycloak/web";
import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {ProfileContext} from "../../../context/ProfileContext";

function AppHeader() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const keycloak = useKeycloak();
    const profile = useContext(ProfileContext);

    const handleClick = (event: any) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    function logout() {
        setCookie("clause1", "", 1)
        return keycloak.keycloak.logout();
    }

    function setCookie(cname: string, cvalue: string, exdays: number) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    return (
        <Grid container className="AppHeader">
            <Grid xs={12} md={2} className="AppHeaderLogoWrapper">
                <Link to="/"><img src={require('../../../img/epismo-logo.png')} alt="Logo e-kuria"/></Link>
            </Grid>
            <Grid xs={12} md={7} className="AppHeaderTitle">
                <Typography variant="body2">Zalogowany jako</Typography>
                <Typography variant="h5">{profile?.attributes['displayed_name']}</Typography>
                <Typography>{profile?.attributes['displayed_address']}</Typography>
            </Grid>
            <Grid xs={12} md={3} className="AppHeaderButtonWrapper">
                {/*<AppHeaderButton*/}
                {/*    name="Ustawienia"*/}
                {/*    icon={require('./icons/ustawienia.png')}*/}
                {/*    action={handleClick}*/}
                {/*/>*/}
                {/*<Menu*/}
                {/*    anchorEl={anchorEl}*/}
                {/*    open={openMenu}*/}
                {/*    onClose={handleClose}*/}
                {/*>*/}
                {/*    <Link to="/changePassword"><MenuItem onClick={handleClose}>Zmiana hasła</MenuItem></Link>*/}
                {/*</Menu>*/}
                <AppHeaderButton
                    name="Do ekuria.pl"
                    icon={require('./icons/ekuria-epismo.png')}
                    action={() => window.location.href='https://ekuria.pl'}
                /><AppHeaderButton
                    name="Wyloguj"
                    icon={require('./icons/wyloguj.png')}
                    action={() => logout()}
                />
            </Grid>
        </Grid>
    )
}

export default AppHeader;