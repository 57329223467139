import {Button, Grid, Typography} from "@mui/material";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import AppHeaderButton from "./AppHeaderButton";
import {useKeycloak} from "@react-keycloak/web";
import React, {useContext} from "react";
import {Link} from "react-router-dom";
import {ProfileContext} from "../../../context/ProfileContext";

function AppHeader() {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const openMenu = Boolean(anchorEl);
    const {keycloak, initialized} = useKeycloak();


    return (
        <Grid container className="AppHeader">
            <Grid xs={12} md={2} className="AppHeaderLogoWrapper">
                <Link to="/"><img src={require('../../../img/epismo-logo.png')} alt="Logo e-kuria"/></Link>
            </Grid>
            <Grid xs={0} md={8} />
            <Grid xs={12} md={2} >
                <Button fullWidth variant="contained" onClick={e => keycloak.login()}>Zaloguj</Button>

            </Grid>
        </Grid>
    )
}

export default AppHeader;