import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    Typography
} from "@mui/material";
import './PrivateLayout.scss';
import {Link, NavLink} from "react-router-dom";
import {ReactNode, useEffect, useState} from "react";
import AppHeader from "./Header/AppHeader";
import {ePismoClientInstance} from "./Service/EPismoClient";
import {useKeycloak} from "@react-keycloak/web";

export default function PrivateLayout(props: {element: ReactNode}) {
    const [showed, setShowed] = useState(false)

    const [open, setOpen] = useState(true);
    const keycloak = useKeycloak();

    useEffect(() => {
        // ePismoClientInstance.get("/api/v1/gui/user/active", keycloak)
        //     .catch(e => alert("Błąd poczas aktywacji skrzynki odbiorczej"));
    }, []);

    const handleClose = () => {
        setCookie("clause1", "1", 30)
        setOpen(false);
    };

    function menuWrapperClassName() {
        if(showed){
            return "menuWrapper showed";
        }
        return "menuWrapper";
    }

    function setCookie(cname: string, cvalue: string, exdays: number) {
        const d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        let expires = "expires="+ d.toUTCString();
        document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
    }

    function getCookie(cname: string) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for(let i = 0; i <ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                return c.substring(name.length, c.length);
            }
        }
        return "";
    }

    return (
        <div>
            {getCookie("clause1") !== "1" &&
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        Regulamin serwisu
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            <p>Korzystając z serwisu akceptujesz <a href="/regulamin-epismo.pdf">regulamin</a>.</p>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} variant="contained">
                            Akceptuję
                        </Button>
                    </DialogActions>
                </Dialog>
            }
            <AppHeader/>
            <Button className="MenuMobileButton" fullWidth onClick={() => setShowed(!showed)}>Menu</Button>
            <Grid container className="privateWrapper">
                <Grid xs={12} md={4} lg={3} className={menuWrapperClassName()}>
                    <Box className="menu">
                        <NavLink to="/" ><span>Aktualne dane parafii</span></NavLink>
                        <NavLink reloadDocument to="/nadaj-pismo"><span>Nadaj pismo</span></NavLink>
                        <NavLink reloadDocument to="/sprawdz-pismo"><span>Sprawdź sygnaturę pisma</span></NavLink>
                        <NavLink to="/lista-pism"><span>Pisma</span></NavLink>
                        <NavLink to="/subskrypcje" ><span>Subskrypcje</span></NavLink>
                    </Box>
                </Grid>

                <Grid xs={12} md={8} lg={9}>
                    <Box className="privateContentWrapper">
                        {props.element}
                    </Box>
                </Grid>
            </Grid>
        </div>
    )
}
