import './CheckDocument.scss'
import CheckDocumentForm from "./CheckDocumentForm";
import {useEffect, useState} from "react";
import CheckDocumentResult from "./CheckDocumentResult";

export default function CheckDocument() {
    const [signature, setSignature] = useState("")

    useEffect(() => {
        const queryParameters = new URLSearchParams(window.location.search)
        const number = queryParameters.get("number")
        if(number) {
            setSignature(number)
        } else {
            setSignature("")
        }
    }, []);
    function selectSignature (number: string) {
        setSignature(number);
    }

    useEffect(() => {
        if(signature) {
            let url = new URL(window.location.toString())
            url.searchParams.set("number",signature)
            window.history.pushState({}, "", url)
        }
    }, [signature]);

    return (
        <div className="CheckDocument">
            {!signature && <CheckDocumentForm callback={selectSignature}/>}
            {signature && <CheckDocumentResult signature={signature}/>}
        </div>
    )
}