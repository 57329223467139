import './DocumentList.scss'
import {
    Button,
    Grid,
    Pagination,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography
} from "@mui/material";
import React, {useEffect, useState} from "react";
import SearchIcon from '@mui/icons-material/Search';
import {useKeycloak} from "@react-keycloak/web";
import {ePismoClientInstance} from "../Service/EPismoClient";

type DocumentSignatureDto = {
    type: string,
    number: string,
    documentDate: string,
    from : {
        name: string,
        address: string,
        id: number
    },
    to : {
        name: string,
        address: string,
        id: number
    },
    archived: boolean
}

type GetDocumentDto = {
    count:number,
    documents: DocumentSignatureDto[]
}
export default function DocumentList() {

    const keycloak = useKeycloak();

    const [query, setQuery] = useState("")
    const [offset, setOffset] = useState(0)
    const [data, setData] = useState<GetDocumentDto>({count: 0, documents: []})
    const [loading, setLoading] = useState(false)

    function load() {
        setLoading(true)
        let params = new URLSearchParams();
        if (query)
            params.set("query", query)
        if (offset)
            params.set("offset", offset.toString())
        ePismoClientInstance.get("/api/v1/gui/documents?" + params.toString(), keycloak).then(r => {
            setData(r.data)
            setLoading(false)
        })
    }

    const downloadFile = (number: string) => {
        ePismoClientInstance.getFile('/api/v1/gui/documents/' + number + "/pdf", keycloak)
            .then(response => {
                const href = URL.createObjectURL(response.data);

                // create "a" HTML element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', number + '.pdf'); //or any other extension
                document.body.appendChild(link);
                link.click();

                // clean up "a" element & remove ObjectURL
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
            })
    }

    useEffect(() => {
        load();
    }, [offset]);
    return (
        <div className="DocumentList">
            <Grid container spacing={3}>
                <Grid xs={12}>
                    <Typography align="left" variant="h1">Lista pism</Typography>
                </Grid>
                <Grid xs={12}>
                    <div style={{textAlign:"left"}}>
                        <TextField onChange={e => setQuery(e.target.value)} label="Szukaj" size="small" sx={{mr: 2}} disabled={loading}/>
                        <Button variant="contained" onClick={load}>
                            {!loading && <><span style={{marginRight:5}}>Szukaj</span> <SearchIcon /></>}
                            {loading && <>Trwa ładowanie...</>}
                        </Button>
                    </div>
                </Grid>
                <Grid xs={12}>
                    <Table>
                        <TableHead>
                            <TableCell width="30%">Rodzaj dokumentu / Nr sygnatury</TableCell>
                            <TableCell width="10%">Data podpisania</TableCell>
                            <TableCell width="30%">Nadawca</TableCell>
                            <TableCell width="30%">Odbiorca</TableCell>
                        </TableHead>
                        <TableBody>
                            {data.documents.map( r =>
                                <TableRow>
                                    <TableCell>
                                        {!r.archived && <span className="link" onClick={e => downloadFile(r.number)}>{r.type}</span>}
                                        {r.archived && <span>Dokument zarchiwizowany</span>}
                                        {r.number}</TableCell>
                                    <TableCell>{r.documentDate}</TableCell>
                                    <TableCell><span>{r.from.name}</span> <span>{r.from.address}</span></TableCell>
                                    <TableCell><span>{r.to.name}</span> <span>{r.to.address}</span></TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    <Pagination count={Math.floor(data.count/5) + 1} onChange={(e, n) => setOffset((n-1)*5)} />
                </Grid>
            </Grid>
        </div>
    )
}