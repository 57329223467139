export type DocumentType = {
    code: Number;
    displayedName: String;
}

export type DocumentDescriptor = {
    name: string,
    fields: DocumentFieldDescriptor[]
}

export type DocumentFieldDescriptor = {
    key: string,
    label: string,
    type: DocumentFieldType,
    required: boolean
}

export enum DocumentFieldType {
    TEXT = 'TEXT', NUMBER = 'NUMBER', DATE = 'DATE', TEXT_AREA = 'TEXT_AREA'
}

export const initDocumentTypes: DocumentType[] = []

export type ContactData = {
    id: string,
    name: string,
    address: string,
    filledEmail: boolean
}

export type Subscription = {
    remainsDocumentsinMonth: number;
    documentsLimit: number;
    activeTo: string;
    activeFrom: string;
    subscriptionName: string;
}