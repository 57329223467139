import {Box, Button, Typography} from "@mui/material";
import {useContext} from "react";
import {ProfileContext} from "../../../context/ProfileContext";
import './ProfileView.scss'
export default function ProfileView() {
    const profile = useContext(ProfileContext);

    function default_string(s: String) {
        if(s)
            return s
        else
            return "Pusto"
    }

    return (
        <Box className="ProfileData">
            <Typography variant="h3" sx={{mb: 4}}>{profile.attributes.displayed_name}</Typography>
            <Box sx={{mb: 2}}>
                <Typography><b>Adres</b></Typography>
                <Typography>{default_string(profile.attributes.displayed_address)}</Typography>
            </Box>
            <Box sx={{mb: 2}}>
                <Typography><b>Proboszcz</b></Typography>
                <Typography>{default_string(profile.attributes.responsible_person)}</Typography>
            </Box>
            <Box sx={{mb: 2}}>
                <Typography><b>Numer telefonu</b></Typography>
                <Typography>{default_string(profile.attributes.phone_number)}</Typography>
            </Box>
            <Box sx={{mb: 3}}>
                <Typography><b>Adres email</b></Typography>
                <Typography>{default_string(profile.email)}</Typography>
            </Box>
            <div style={{textAlign: "left"}}><Button variant="contained" disabled={true}>Edytuj dane</Button></div>



        </Box>
    )
}