import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {ReactKeycloakProvider} from "@react-keycloak/web";
import Keycloak from "keycloak-js";
import {LocalizationProvider, plPL} from "@mui/x-date-pickers";
import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {createTheme, ThemeProvider} from "@mui/material";
import "dayjs/locale/pl"

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const keycloak = new Keycloak({
    url: `${process.env.REACT_APP_KEYCLOAK_URL}`,
    realm: `${process.env.REACT_APP_KEYCLOAK_REALM}`,
    clientId: `${process.env.REACT_APP_KEYCLOAK_CLIENT_ID}`
});

root.render(
    <ThemeProvider theme={createTheme({}, plPL)}>
        <ReactKeycloakProvider authClient={keycloak}>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pl">
                <App/>
            </LocalizationProvider>
        </ReactKeycloakProvider>
    </ThemeProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
